import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment } from 'react';
import { SubscriptionTier } from '../context/FeatureGate/FeatureGate';
import logo from '../images/logo_vertical.png';

export default function LimitedReachedUpgradeModal({
  isOpen,
  setIsOpen,
  limitResource,
  limit,
  tier
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  limitResource: 'meeting notes' | 'clients' | 'custom templates';
  limit: number;
  tier: SubscriptionTier;
}) {
  const handleUpgrade = () => {
    window.open('https://buy.stripe.com/28o6oofSa5PK4akaEG', '_blank');
    setIsOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-6 sm:px-10 sm:py-12">
                {/* Logo */}
                <div className="flex justify-center mb-8">
                  {/*import logo from '../images/logo_vertical.png'*/}
                  <img src={logo} alt="NoteWell Logo" className="h-20" />
                </div>

                <div className="text-center">
                  <DialogTitle as="h1" className="text-3xl font-bold text-gray-900 mb-4">
                    Need more {limitResource}?
                  </DialogTitle>

                  <p className="text-base text-gray-600 mb-8 text-justify">
                    You've reached the maximum of {limit} {limitResource} allowed for your current{' '}
                    <b>{tier}</b> plan. To create more {limitResource}, upgrade for expanded access
                    and additional features.
                  </p>
                  <div className="text-left mb-8">
                    <ul className="space-y-3">
                      <li className="flex items-center text-base text-gray-700">
                        <span>
                          • Add <span className="font-semibold text-teal-700">unlimited</span>{' '}
                          clients.
                        </span>
                      </li>
                      <li className="flex items-center text-base text-gray-700">
                        <span>
                          • Create <span className="font-semibold text-teal-700">unlimited</span>{' '}
                          meeting templates.
                        </span>
                      </li>
                      <li className="flex items-center text-base text-gray-700">
                        <span>
                          • Generate <span className="font-semibold text-teal-700">unlimited</span>{' '}
                          meeting notes.
                        </span>
                      </li>
                      <li className="flex items-center text-base text-gray-700">
                        <span>
                          • <span className="font-semibold text-teal-700">Share notes</span> with
                          clients or colleagues.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <button
                    type="button"
                    className="w-full bg-teal-500 text-white py-3 rounded-lg text-base font-semibold hover:bg-teal-400 transition-colors duration-300 shadow-sm"
                    onClick={handleUpgrade}
                  >
                    Unlock for $14.99
                  </button>
                  <p className="mt-4 text-xs text-gray-500">
                    This is a monthly subscription. By proceeding, you agree to our{' '}
                    <a
                      href="https://www.notewell.ai/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-teal-600 hover:text-teal-500"
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-teal-600 hover:text-teal-500"
                    >
                      Terms of Service
                    </a>
                    .
                  </p>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
