export enum SubscriptionTier {
  FREE = 'Free',
  PLUS = 'Plus',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise'
}

export type TierLimit = {
  maxClients: number;
  maxMeetingNotes: number;
  maxCustomTemplates: number;
};

export const tierLimits: Record<SubscriptionTier, TierLimit> = {
  [SubscriptionTier.FREE]: {
    maxClients: 3,
    maxMeetingNotes: 5,
    maxCustomTemplates: 1
  },
  [SubscriptionTier.PLUS]: {
    maxClients: Infinity, // Unlimited
    maxMeetingNotes: Infinity, // Unlimited
    maxCustomTemplates: Infinity // Unlimited
  },
  [SubscriptionTier.PRO]: {
    maxClients: Infinity, // Unlimited
    maxMeetingNotes: Infinity, // Unlimited
    maxCustomTemplates: Infinity // Unlimited
  },
  [SubscriptionTier.ENTERPRISE]: {
    maxClients: Infinity, // Unlimited
    maxMeetingNotes: Infinity, // Unlimited
    maxCustomTemplates: Infinity // Unlimited
  }
};

export class FeatureGate {
  userTierLimits;

  constructor(public userTier: SubscriptionTier) {
    this.userTierLimits = tierLimits[userTier];
  }

  public isPaidTier() {
    return this.userTier !== SubscriptionTier.FREE;
  }

  public canCreateClient(clientCount: number) {
    return clientCount < this.userTierLimits.maxClients;
  }

  public canCreateMeetingNote(meetingNoteCount: number) {
    return meetingNoteCount < this.userTierLimits.maxMeetingNotes;
  }

  public canCreateCustomTemplate(customTemplateCount: number) {
    return customTemplateCount < this.userTierLimits.maxCustomTemplates;
  }

  public get clientLimit() {
    return this.userTierLimits.maxClients;
  }

  public get meetingNoteLimit() {
    return this.userTierLimits.maxMeetingNotes;
  }

  public get templateLimit() {
    return this.userTierLimits.maxCustomTemplates;
  }
}

export default FeatureGate;
