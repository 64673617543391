import { useMutation, useQuery } from '@tanstack/react-query';
import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';
import { IoCopyOutline, IoSend } from 'react-icons/io5';
import { useLocation, useParams } from 'react-router-dom';
import loadingAnimation from '../animations/loading-animation.json';
import { sendContextualMessage, sendMessage } from '../clients/chatClient';
import { getMeetingById, getMeetings } from '../clients/meetingClient';
import { Meeting, ProcessingStatus } from '../common/types';
import { classNames, sortMeetings } from '../common/utils/utils';
import AssistantLogo from '../components/AssistantLogo';
import MarkdownRenderer from '../components/MarkdownRender';
import SelectMeetingNoteModal from '../components/SelectMeetingNoteModal';
import Sidebar from '../components/Sidebar';
import UpgradeModal from '../components/UpgradeModal';
import { useFeatureGate } from '../context/FeatureGate/FeatureGateContext';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface Query {
  label: string;
  value: string;
}

const generalWelcomeMessage =
  'Notewell AI is here to assist you! I am a HIPAA-compliant chat. Let me know how I can help you.';
const contextualWelcomeMessage =
  'I have the context from your meeting notes. What would you like to do next? I can turn your notes into referral letters, case plans, and more!';

export default function ChatPage() {
  // Load params
  const params = useParams();
  const meetingId = Number(params?.meetingId) || -1;
  const hasMeetingContext = meetingId !== -1;
  const [copiedMessage, setCopiedMessage] = useState<string | null>(null);

  const [message, setMessage] = useState<Message>({ role: 'user', content: '' });
  const [chatHistory, setChatHistory] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectMeetingNoteModalOpen, setIsSelectMeetingNoteModalOpen] = useState(false);
  const previousMessageQueueLength = 8;
  // Extract the type of action we are taking sent from the meeting notes page
  const location = useLocation();
  const isGenericAction = location.state?.isGenericAction || false;

  const { featureGate } = useFeatureGate();
  const shouldShowUpgradeButton = featureGate && !featureGate.isPaidTier();

  // Create a ref for the bottom of the messages container
  const bottomRef = useRef<HTMLDivElement>(null);

  // Ref for the textarea
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Maximum height in pixels (9 lines x approx 24px per line)
  const MAX_HEIGHT = 9 * 24;

  // Max number of words per line for the user input
  const MAX_WORDS_PER_LINE = 15;

  // Pre-set queries based on the context of the meeting
  const generalQueries: Query[] = [
    { label: 'Mental health resources', value: 'Please list key mental health resources' },
    {
      label: 'Trauma-informed care',
      value: 'How can I incorporate trauma-informed care in my practice?'
    }
    // { label: 'Harm reduction strategies', value: 'Harm reduction strategies' }
  ];
  const contextualQueries: Query[] = [
    { label: 'Summarize the meeting', value: 'Please summarize the meeting' },
    { label: 'Build a case plan', value: 'Please build a case plan' },
    { label: 'Create a progress note', value: 'Please create a progress note' }
    // {label: 'Create a referral letter', value: 'Please create a referral letter.'},
  ];
  const queryButtonColors: string[] = [
    'bg-indigo-50/80 text-indigo-800 border-indigo-500/70 hover:bg-indigo-600/80 hover:border-indigo-600/80',
    'bg-sky-50 text-sky-800 border-sky-500/70 hover:bg-sky-500 hover:border-sky-500',
    'bg-teal-50/80 text-teal-800 border-teal-500/70 hover:bg-teal-600/80 hover:border-teal-600/80'
  ];
  const presetQueries: Query[] = meetingId === -1 ? generalQueries : contextualQueries;

  const handlePresetButtonClick = (index: number) => {
    if (index >= presetQueries.length) return;
    const query = presetQueries[index].value;
    const message: Message = { role: 'user', content: query };
    setMessage(message);
    sendUserMessage(message);
  };

  // Fetch all meetings
  const { data: meetings, error: meetingNotesError } = useQuery({
    enabled: !hasMeetingContext, // Only need to load notes in general chat
    queryKey: ['getMeetings'],
    queryFn: getMeetings
  });
  // Only keep completed meeting notes
  const filteredMeetingNotes = meetings
    ? meetings.filter((meeting: Meeting) => meeting.status === ProcessingStatus.COMPLETED)
    : [];
  // Sort meeting notes by date in descending order
  const meetingNotes = sortMeetings(filteredMeetingNotes, true);

  // Fetch meeting note
  const {
    data: meetingNote,
    isFetching,
    refetch
  } = useQuery({
    enabled: hasMeetingContext,
    queryKey: ['getMeetingById', meetingId],
    queryFn: () => getMeetingById(meetingId),
    staleTime: Infinity // Prevents refetching when navigating back
  });

  // Construct meeting note link text for chat message
  const constructMeetingNoteText = (meetingNote: Meeting | undefined): string => {
    let meetingNoteText;
    if (meetingNote) {
      meetingNoteText = `meeting notes, [${meetingNote.title}]`;
    } else {
      meetingNoteText = `[meeting notes]`;
    }
    meetingNoteText += `(/meeting-notes/${meetingId})`;
    return meetingNoteText;
  };

  const constructContextualWelcomeMessage = (): string => {
    const meetingNoteLinkText = constructMeetingNoteText(meetingNote);
    return contextualWelcomeMessage.replace('meeting notes', meetingNoteLinkText);
  };

  // Initialize chat history with a generic welcome message if no meeting note context
  // This is especially necessary because the component does not remount when the meetingId changes
  useEffect(() => {
    if (!hasMeetingContext) setChatHistory([{ role: 'assistant', content: generalWelcomeMessage }]);
    else refetch();
  }, [meetingId]);

  // When meeting note is done being fetched, display a contextual welcome message
  useEffect(() => {
    if (hasMeetingContext && isFetching === true) setIsLoading(true);
    if (hasMeetingContext && isFetching === false) {
      setIsLoading(false);
      setChatHistory([{ role: 'assistant', content: constructContextualWelcomeMessage() }]);
    }
  }, [isFetching]);

  // Whenever chatHistory or isLoading changes, scroll to the bottom
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory, isLoading]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [chatHistory]); // Re-focus when messages update

  const sendMessageMutation = useMutation({
    mutationFn: (msg: Message) => {
      if (hasMeetingContext) {
        // Send contextual message if conversation id is present
        return sendContextualMessage(
          msg,
          chatHistory.slice(-previousMessageQueueLength),
          meetingId
        );
      }
      return sendMessage(msg, chatHistory.slice(-previousMessageQueueLength));
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      const newMessage: Message = {
        role: data.role,
        content: data.content
      };
      setChatHistory((prev) => [...prev, newMessage]);
      setIsLoading(false);
    },
    onError: (error) => {
      console.error(error);
      setIsLoading(false);
    }
  });

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopiedMessage(text);
  };

  const renderPresetButtons = () => {
    return (
      <div className="flex mt-6">
        {/* Load a meeting note button, only rendered in general chat */}
        {!hasMeetingContext && (
          <button
            className={classNames(
              'text-sm font-medium px-6 py-2 mx-2 border border-gray-300 rounded-full hover:text-white transition-color duration-300',
              queryButtonColors[presetQueries.length % queryButtonColors.length]
            )}
            onClick={() => setIsSelectMeetingNoteModalOpen(true)}
          >
            Load a meeting note
          </button>
        )}

        {presetQueries.map((query: Query, index: number) => (
          <button
            className={classNames(
              'text-sm font-medium px-6 py-2 mx-2 border border-gray-300 rounded-full hover:text-white transition-color duration-300',
              queryButtonColors[index % queryButtonColors.length]
            )}
            onClick={() => handlePresetButtonClick(index)}
          >
            {query.label}
          </button>
        ))}
      </div>
    );
  };

  const renderMessages = () => {
    return chatHistory.map((msg: Message, index) => (
      <div
        key={index}
        className={`p-2 rounded mb-2 flex items-start ${
          msg.role === 'user' ? 'justify-end' : 'justify-start'
        }`}
      >
        {msg.role === 'assistant' && <AssistantLogo />}

        <div className="mt-2 flex flex-col group">
          {msg.role === 'assistant' ? (
            <>
              <MarkdownRenderer content={msg.content} />
              {/* Do not render copy button for welcome message */}
              {index > 0 && (
                <div className="mt-2 w-fit">
                  <button
                    onClick={() => handleCopy(msg.content)}
                    onMouseEnter={() => setCopiedMessage(null)}
                    className="inline-flex items-center gap-1 text-sm font-medium rounded-lg px-2.5 py-1.5 text-indigo-800/70 bg-indigo-50 hover:bg-indigo-500 hover:text-white opacity-0 transition-color duration-300 group-hover:opacity-100"
                  >
                    {copiedMessage === msg.content ? (
                      'Copied!'
                    ) : (
                      <>
                        <IoCopyOutline className="h-4 w-4" />
                        Copy
                      </>
                    )}
                  </button>
                </div>
              )}
              {/* Render pre-set buttons only for welcome message, and hide them once the chat is underway */}
              {index === 0 && chatHistory.length === 1 && renderPresetButtons()}
            </>
          ) : (
            // mb-6 - assistant messages have a lot of bottom margin due to copy button. we need to match that spacing with the user messages
            <span className="whitespace-pre-wrap inline-block bg-indigo-50 rounded-2xl px-5 py-2.5 mb-6">
              {formatContent(msg.content)}
            </span>
          )}
        </div>
      </div>
    ));
  };

  const sendUserMessage = (message: Message) => {
    const messageText: string = message.content;
    if (messageText.trim() === '') return;

    setChatHistory((prev) => [...prev, message]);
    sendMessageMutation.mutate(message);
    setMessage({ role: 'user', content: '' });
    // Reset the textarea height after sending
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendUserMessage(message);
  };

  // Handle textarea auto-resizing
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setMessage({ role: 'user', content: newContent });
    if (textareaRef.current) {
      // Reset height to recalc scrollHeight correctly
      textareaRef.current.style.height = 'auto';
      // Set the new height (up to MAX_HEIGHT)
      // Reset height to auto to correctly read scrollHeight
      textareaRef.current.style.height = 'auto';
      const newHeight = Math.min(textareaRef.current.scrollHeight, MAX_HEIGHT);
      textareaRef.current.style.height = `${newHeight}px`;
      // Hide scrollbar unless content exceeds MAX_HEIGHT
      textareaRef.current.style.overflowY =
        textareaRef.current.scrollHeight > MAX_HEIGHT ? 'auto' : 'hidden';
    }
  };

  const formatContent = (content: string): string => {
    const words = content.split(/\s+/);
    let formatted = '';
    for (let i = 0; i < words.length; i++) {
      formatted += words[i] + ' ';
      if ((i + 1) % MAX_WORDS_PER_LINE === 0) {
        formatted += '\n';
      }
    }
    return formatted.trim();
  };

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  return (
    <>
      <Sidebar currentPageName={'Notewell AI'} />
      <main className="lg:ml-72 h-screen flex flex-col">
        <div className="relative w-full">
          {/* Upgrade Button*/}
          {shouldShowUpgradeButton && (
            <button
              onClick={() => setIsUpgradeModalOpen(true)}
              className="absolute flex items-center top-6 right-6 z-50 
                 hover:bg-teal-400 text-sm font-semibold text-white
                 px-3.5 py-2 rounded-lg shadow-lg bg-teal-500
                 transition duration-300"
            >
              <p>Upgrade</p>
            </button>
          )}
        </div>
        {/* Heading */}
        <div className="px-4 pt-10 lg:px-12 text-gray-800">
          <h3 className="text-3xl font-bold">Chat</h3>
        </div>
        {/* Scrollable messages container */}
        <div className="flex-grow overflow-y-auto bg-white p-6">
          <div className="max-w-3xl mx-auto">
            {renderMessages()}
            {isLoading && (
              <div className="flex items-center mb-2">
                {/* Logo container matching the assistant message layout */}
                <div className="ml-2 mt-[-14px]">
                  <AssistantLogo />
                </div>
                {/* Loading animation container aligned with items-center */}
                <div className="flex items-center">
                  <Lottie
                    animationData={loadingAnimation}
                    loop
                    autoPlay
                    style={{
                      width: '100px',
                      height: '80px',
                      marginLeft: '-12px',
                      marginTop: '-10px'
                    }}
                  />
                </div>
              </div>
            )}
            {/* Dummy element to anchor the bottom for scrolling */}
            <div ref={bottomRef} />
          </div>
        </div>

        {/* Fixed input container */}
        <form onSubmit={handleSubmit} className="bg-white p-4 mb-8 lg:mb-12">
          <div className="max-w-3xl mx-auto">
            {/* Pill-shaped input container */}
            <div
              onClick={() => textareaRef.current?.focus()}
              className="flex flex-col bg-gray-50 focus-within:bg-indigo-50/50 rounded-lg shadow-sm p-4 cursor-text border border-gray-300 focus-within:border-transparent bg-white focus-within:shadow-[0_0_6px_rgba(99,102,241,0.7)] transition-color duration-200"
            >
              <textarea
                autoFocus
                ref={textareaRef}
                value={message.content}
                onChange={handleTextareaChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    sendUserMessage(message);
                  }
                }}
                placeholder="Type your message..."
                rows={1}
                style={{ resize: 'none', overflowY: 'auto' }}
                className="flex-grow bg-transparent focus:outline-none text-gray-800 focus:placeholder-indigo-800/50 placeholder:font-medium"
                disabled={isLoading}
              />
              <button
                type="submit"
                className="mt-2 self-end text-indigo-500 hover:text-indigo-600 disabled:text-gray-400"
                disabled={isLoading}
              >
                <IoSend size={20} />
              </button>
            </div>
          </div>
        </form>
        <SelectMeetingNoteModal
          isOpen={isSelectMeetingNoteModalOpen}
          setIsOpen={setIsSelectMeetingNoteModalOpen}
          meetingNotes={meetingNotes}
          error={meetingNotesError}
        />
        <UpgradeModal isOpen={isUpgradeModalOpen} setIsOpen={setIsUpgradeModalOpen} />
      </main>
    </>
  );
}
