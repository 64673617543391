import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, useState } from 'react';
import Select from 'react-select';
import useNavigation from '../common/navigation';
import { Meeting } from '../common/types';

export default function SelectMeetingNoteModal({
  isOpen,
  setIsOpen,
  meetingNotes,
  error
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  meetingNotes: Meeting[];
  error: Error | null;
}) {
  const { goToChatPageWithContext } = useNavigation();
  const [selectedNoteId, setSelectedNoteId] = useState<number>();
  const meetingNoteOptions: { value: number; label: string }[] = meetingNotes.map((note) => ({
    value: note.id!,
    label: note.title
  }));

  const loadChatWithNoteContext = () => {
    if (!selectedNoteId) return;
    goToChatPageWithContext(selectedNoteId);
    setIsOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-6 sm:px-10 sm:py-12">
                <div>
                  <div className="text-center">
                    <DialogTitle as="h1" className="text-2xl font-bold text-gray-900 mb-4">
                      Select a Meeting Note
                    </DialogTitle>
                    <div>
                      <p className="text-gray-700 mb-4">
                        Notewell AI will load your meeting note and use it as context when answering
                        your questions.
                      </p>

                      <div className="flex flex-col gap-2 mt-6">
                        {meetingNotes ? (
                          <Select
                            onChange={(selectedOption: any) =>
                              setSelectedNoteId(selectedOption.value)
                            }
                            options={meetingNoteOptions}
                            placeholder="Select a meeting note..."
                            menuPortalTarget={document.body} // Render dropdown in the document body so it isn'tc cut off by the modal
                            styles={reactSelectStyles}
                          />
                        ) : error !== null ? (
                          <p>There was an error loading your meeeting notes. Please try again.</p>
                        ) : (
                          <p>You don't have any meeting notes yet. </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-8 flex justify-center">
                  <button
                    disabled={!selectedNoteId}
                    type="button"
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 disabled:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-color duration-300"
                    onClick={loadChatWithNoteContext}
                  >
                    Load Meeting Note
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const reactSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    border: 'none', // Remove all borders
    borderBottom: '2px solid #4F46E5', // Indigo-600 underline
    boxShadow: 'none', // Prevent blue focus border
    '&:hover': {
      borderBottom: '2px solid #4338CA' // Darker indigo on hover
    },
    borderRadius: 0 // Remove border radius
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: '0px' // Remove border radius from dropdown
  }),
  option: (base: any, { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }) => ({
    ...base,
    backgroundColor: isSelected
      ? '#4f46e5' // Indigo for selected option
      : isFocused
        ? '#dce5ff' // Lighter indigo for hover
        : 'white',
    color: isSelected ? 'white' : 'black',
    '&:active': {
      backgroundColor: '#4338ca' // Darker indigo when clicking
    }
    // fontSize: '14px'
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
};
