import { classNames } from '../common/utils/utils';

export default function ButtonPlaceholder({ className }: { className?: string }) {
  return (
    <div
      className={classNames(
        className,
        'mt-4 inline-flex w-32 h-9 items-center rounded-md bg-indigo-300/50 px-4 py-2 text-sm font-semibold text-white shadow-sm animate-pulse text-transparent'
      )}
    />
  );
}
