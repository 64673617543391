import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import {
  createTemplateBlock,
  deleteTemplateBlockById,
  updateTemplateBlockById
} from '../clients/templateBlockClient';
import { updateTemplateById } from '../clients/templateClient';
import {
  QuestionParentType,
  Template,
  TemplateBlock as TemplateBlockType,
  TemplateElements,
  TemplateElementsWithLength,
  TemplateElementsWithOptions
} from '../common/types';
import { useGlobalContext } from '../context/GlobalProvider';
import useDebounce from '../hooks/useDebounce';
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning';
import AddQuestionMenu from './AddQuestionMenu';
import ErrorBanner from './ErrorBanner';
import LengthofList from './LengthofList';
import {
  compareTemplateBlocks,
  doesBlockNeedLength,
  doesBlockNeedOptions
} from './MeetingTemplateBuilder.utils';
import OptionList from './OptionList';
import SaveStatusIndicator, { SaveStatus } from './SaveStatusIndicator';
import TemplateBlock from './TemplateBlock';

const SAVE_DELAY = 1000;
const DEBOUNCE_DELAY = 1000;

export default function MeetingTemplateBuilder({
  isNewTemplate,
  template,
  blocks,
  isReadOnly
}: {
  isNewTemplate: boolean;
  template: Template;
  blocks: TemplateBlockType[];
  isReadOnly: boolean;
}) {
  // If template is new, set title to empty string so user can see placeholder text
  const title = isNewTemplate ? '' : template.title;

  const [initialTemplateTitle, setInitialTemplateTitle] = useState<string>(title);
  const [templateTitle, setTemplateTitle] = useState<string>(title);
  const [initialTemplateBlocks, setInitialTemplateBlocks] = useState<TemplateBlockType[]>(blocks);
  const [templateBlocks, setTemplateBlocks] = useState<TemplateBlockType[]>(blocks);
  const [uniqueBlockId, setUniqueBlockId] = useState<number>(0);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.SAVED);

  // ##PreventNewBlockUpdates
  // Set of block IDs that have been created but not yet persisted to the database. These
  // ids are local/temporary ids (1, 2, 3...n) that do NOT correspond to db ids. Therefore
  // it is dangerous to perform any db operations on these blocks, since the ids may belong
  // to real blocks in the db that are not the intended ones.  We block these in two ways:
  // UI-blocking (disabling the block in the UI) and business-logic blocking (preventing any
  // mutations to be run on blocks with ids in this set).
  const [newTemplateBlockIds, setNewTemplateBlockIds] = useState<Set<number>>(new Set());

  const newBlockRef = useRef<HTMLDivElement>(null); // For anchoring to and highlighting a newly created template block
  const [highlightIndex, setHighlightIndex] = useState<number | undefined>();

  const queryClient = useQueryClient();
  const { setState } = useGlobalContext();

  //============================= DRAG AND DROP ===============================
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setTemplateBlocks((blocks: TemplateBlockType[]) => {
        const oldIndex = blocks.findIndex((block: TemplateBlockType) => block.id === active.id);
        const newIndex = blocks.findIndex((block: TemplateBlockType) => block.id === over.id);

        return arrayMove(blocks, oldIndex, newIndex);
      });
    }
  };
  //============================= END DRAG AND DROP ===============================

  const hasChanges = () => {
    return (
      templateTitle !== initialTemplateTitle ||
      JSON.stringify(templateBlocks) !== JSON.stringify(initialTemplateBlocks)
    );
  };

  const markNewBlock = (id: number) => {
    setNewTemplateBlockIds((prev) => prev.add(id));
  };

  const unmarkNewBlock = (id: number) => {
    setNewTemplateBlockIds((prev) => {
      prev.delete(id);
      return prev;
    });
  };

  // Display confirmation window if user navigates away with unsaved changes
  useUnsavedChangesWarning(hasChanges);

  const handleSuccess = () => {
    // Add an extra three seconds to the timeout to ensure the user sees the success message
    setTimeout(() => {
      setSaveStatus(SaveStatus.SAVED);
    }, SAVE_DELAY);
  };

  //=============================== MUTATIONS ==============================================
  const updateTemplateMutation = useMutation({
    mutationFn: ({
      templateId,
      newTemplateTitle
    }: {
      templateId: number;
      newTemplateTitle: string;
    }) => {
      return updateTemplateById(templateId, newTemplateTitle);
    },
    onMutate: ({
      templateId,
      newTemplateTitle
    }: {
      templateId: number;
      newTemplateTitle: string;
    }) => {
      template!.title = newTemplateTitle; // Update template title to reflect new title

      // Optimisically update the template title
      queryClient.setQueryData(['getTemplateAndBlocksById', templateId], (prevData: any) => {
        return {
          ...prevData,
          template: {
            ...prevData.template,
            title: templateTitle
          }
        };
      });
    },
    onSuccess: (_, { newTemplateTitle }) => {
      setInitialTemplateTitle(newTemplateTitle);
    },
    onError: (_, templateId) => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', templateId] });
    }
  });

  const createBlockMutation = useMutation({
    mutationFn: ({ templateId, block }: { templateId: number; block: TemplateBlockType }) => {
      return createTemplateBlock(templateId, block);
    },
    onMutate: ({ templateId, block }: { templateId: number; block: TemplateBlockType }) => {
      // Optimistically update cached template blocks
      queryClient.setQueryData(['getTemplateAndBlocksById', templateId], (prevData: any) => {
        return {
          ...prevData,
          blocks: [...prevData.blocks, block].sort((a, b) => a.position - b.position)
        };
      });
      // Optimistically update initial template blocks with new block
      setInitialTemplateBlocks((prevInitialTemplateBlocks) => {
        const newBlocks = [...prevInitialTemplateBlocks, block];
        newBlocks.sort((a, b) => a.position - b.position);
        return newBlocks;
      });
    },
    onSuccess: (newTemplateBlock: TemplateBlockType, vars) => {
      // Update template block in state with new block id. We do not want to override any further changes,
      // so we only update the block with the new id.
      setTemplateBlocks((prevTemplateBlocks) =>
        prevTemplateBlocks.map((block) =>
          block.id === vars.block.id ? { ...newTemplateBlock } : block
        )
      );
      // Update initial template blocks with new id
      setInitialTemplateBlocks((prevInitialTemplateBlocks) =>
        prevInitialTemplateBlocks.map((block) =>
          block.id === vars.block.id ? { ...newTemplateBlock } : block
        )
      );
      // Update query data with new block id
      queryClient.setQueryData(['getTemplateAndBlocksById', vars.templateId], (prevData: any) => {
        return {
          ...prevData,
          blocks: prevData.blocks.map((block: TemplateBlockType) =>
            block.id === vars.block.id ? { ...newTemplateBlock } : block
          )
        };
      });
      unmarkNewBlock(vars.block.id); // Mark new block as persisted
    },
    onError: (_1, vars) => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', vars.templateId] });
      markNewBlock(vars.block.id); // Add block back into set since it wasn't successfully created
    }
  });

  const updateBlockMutation = useMutation({
    mutationFn: (updatedTemplateBlock: TemplateBlockType) => {
      return updateTemplateBlockById(updatedTemplateBlock);
    },
    onMutate: (updatedTemplateBlock: TemplateBlockType) => {
      // Optimisically update template blocks with updated block
      queryClient.setQueryData(['getTemplateAndBlocksById', template!.id], (prevData: any) => {
        return {
          ...prevData,
          blocks: prevData.blocks
            .map((block: TemplateBlockType) =>
              block.id === updatedTemplateBlock.id ? { ...updatedTemplateBlock } : block
            )
            .sort((a: TemplateBlockType, b: TemplateBlockType) => a.position - b.position)
        };
      });
      // Optimistically update initial template blocks with updated blocks
      const originalBlock = initialTemplateBlocks.find(
        (block) => block.id === updatedTemplateBlock.id
      );
      setInitialTemplateBlocks((prevInitialTemplateBlocks) =>
        prevInitialTemplateBlocks
          .map((block) =>
            block.id === updatedTemplateBlock.id ? { ...updatedTemplateBlock } : block
          )
          .sort((a: TemplateBlockType, b: TemplateBlockType) => a.position - b.position)
      );
      return originalBlock;
    },
    onError: (_, updatedBlock: TemplateBlockType, originalBlock: TemplateBlockType | undefined) => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', template!.id] });
      // Restore the block that was updated
      if (originalBlock)
        setInitialTemplateBlocks((prevInitialTemplateBlocks) =>
          prevInitialTemplateBlocks
            .map((block) => (block.id === updatedBlock.id ? { ...originalBlock } : block))
            .sort((a, b) => a.position - b.position)
        );
    }
  });

  const deleteBlockMutation = useMutation({
    mutationFn: (templateBlockId: number) => {
      return deleteTemplateBlockById(templateBlockId);
    },
    onMutate: (templateBlockId: number) => {
      // Optimisically update template blocks by removing deleted block
      const data = queryClient.getQueryData(['getTemplateAndBlocksById', template.id!]);
      if (data)
        queryClient.setQueryData(['getTemplateAndBlocksById', template!.id], (prevData: any) => {
          return {
            ...prevData,
            blocks: prevData.blocks.filter(
              (block: TemplateBlockType) => block.id !== templateBlockId
            )
          };
        });
      // Optimistically update initial template blocks by removing deleted block
      const deletedBlock = initialTemplateBlocks.find((block) => block.id === templateBlockId);
      setInitialTemplateBlocks((prevInitialTemplateBlocks) =>
        prevInitialTemplateBlocks.filter((block) => block.id !== templateBlockId)
      );
      return deletedBlock;
    },
    onError: (_error, _context, deletedBlock: TemplateBlockType | undefined) => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', template!.id] });
      // Add back the block that was deleted
      if (deletedBlock)
        setInitialTemplateBlocks((prevInitialTemplateBlocks) =>
          [...prevInitialTemplateBlocks, deletedBlock].sort((a, b) => a.position - b.position)
        );
    }
  });
  //=============================== END MUTATIONS ==========================================

  const hasError = () =>
    updateTemplateMutation.isError ||
    createBlockMutation.isError ||
    updateBlockMutation.isError ||
    deleteBlockMutation.isError;

  const debouncedTitle = useDebounce(templateTitle, DEBOUNCE_DELAY);
  const debouncedBlocks = useDebounce(templateBlocks, DEBOUNCE_DELAY);

  useEffect(() => {
    // Do not continue attempting to save if an error has occurred
    if (hasChanges() && !hasError()) {
      handleSubmit();
    }

    // If user insists on continuing to make changes, continually
    // display error message each time they attempt to make a change
    if (hasError())
      setState((prevState) => ({
        ...prevState,
        templateBuilderError: true
      }));
  }, [debouncedTitle, debouncedBlocks]);

  const setBlockQuestionById = (id: number, question: string) => {
    setTemplateBlocks((prevTemplateBlocks) =>
      prevTemplateBlocks.map((block) => (block.id === id ? { ...block, question } : block))
    );
  };

  const setBlockOptionsById = (
    id: number,
    optArgs: { options?: string[]; lengthOfList?: number }
  ) => {
    setTemplateBlocks((prevTemplateBlocks) =>
      prevTemplateBlocks.map((block) => (block.id === id ? { ...block, optArgs } : block))
    );
  };

  /**
   * Triggered by "Add Element" menu. Adds a new template block based on
   * the template element menu item that was selected.
   */
  function addTemplateElement(elementType: TemplateElements) {
    if (!Object.values(TemplateElements).includes(elementType)) {
      alert('Invalid template element selected');
    } else {
      const newTemplateBlock = createTemplateBlockByType(elementType);
      // need to do this to update templateBlocks in an async-safe manner
      setTemplateBlocks((prevTemplateBlocks) => [...prevTemplateBlocks, newTemplateBlock]);
      markNewBlock(newTemplateBlock.id);
      // jump to new template block (which is always the last in the list)
      setTimeout(
        () => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }),
        100 // Delay is needed to give time for new block to be rendered. Without this, will not scroll far enough
      );
      // Highlight newly created block. Remove highlight after some time
      setHighlightIndex(newTemplateBlock.position); // replace with actual index
      setTimeout(() => setHighlightIndex(undefined), 5000); // 5 seconds
    }
  }

  /**
   * Triggered by clicking the delete button for a specific template block.
   * Removes said block from the list of template blocks.
   */
  function removeTemplateElementById(id: number) {
    // need to do this to update templateBlocks in an async-safe manner
    setTemplateBlocks((prevTemplateBlocks) => {
      if (!prevTemplateBlocks.some((block) => block.id === id)) {
        alert('Attempted to delete an invalid block. Please try again.');
      }
      return prevTemplateBlocks.filter((block) => block.id !== id);
    });
  }

  /**
   * Creates a TemplateBlock by:
   * 1. Building a TemplateBlock
   * 2. Adding an options list if needed
   * 3. Using the current uniqueBlockID, and then incrementing it
   */
  const createTemplateBlockByType = (elementType: TemplateElements) => {
    const newTemplateBlock: TemplateBlockType = {
      id: uniqueBlockId,
      question: '',
      type: elementType,
      position: templateBlocks.length,
      parentType: QuestionParentType.Template
    };

    // Add options list to elements that need it
    if (TemplateElementsWithOptions.includes(elementType)) {
      newTemplateBlock.optArgs = {};
      newTemplateBlock.optArgs.options = ['']; // Always start off with a single empty option
    }

    // Add length of list to elements that need it
    if (TemplateElementsWithLength.includes(elementType)) {
      newTemplateBlock.optArgs = {};
      newTemplateBlock.optArgs.lengthOfList = 3;
    }

    // Increment block ID for the next block
    setUniqueBlockId(uniqueBlockId + 1);
    return newTemplateBlock;
  };

  /**
   * - Makes API call to update template and template blocks
   * - Displays successful save modal on success
   */
  const handleUpdate = async (templateId: number) => {
    setSaveStatus(SaveStatus.SAVING);

    try {
      if (templateTitle !== initialTemplateTitle)
        updateTemplateMutation.mutate({
          templateId,
          newTemplateTitle: templateTitle || 'Untitled Template'
        });
    } catch (error) {
      setSaveStatus(SaveStatus.ERROR);
    }

    // Preserve ordering of blocks by updating their positions
    const updatedTemplateBlocks = templateBlocks.map((block, index) => ({
      ...block,
      position: index
    }));
    setTemplateBlocks(updatedTemplateBlocks);

    const { newBlocks, updatedBlocks, deletedBlocks } = compareTemplateBlocks(
      initialTemplateBlocks,
      updatedTemplateBlocks,
      newTemplateBlockIds
    );

    const createPromises = newBlocks.map((block) =>
      createBlockMutation.mutateAsync({ templateId, block })
    );
    const updatePromises = updatedBlocks.map((block) => updateBlockMutation.mutateAsync(block));
    const deletePromises = deletedBlocks.map((block) => deleteBlockMutation.mutateAsync(block.id));

    try {
      await Promise.all([...createPromises, ...updatePromises, ...deletePromises]);
      handleSuccess();
    } catch (error) {
      setSaveStatus(SaveStatus.ERROR);
    }
  };

  /**
   * Handle "Save Meeting Template" button click.
   * - Prevents form submission from refreshing page
   * - Makes API call to create template and template blocks
   * - Marks template as being saved
   * - Displays successful save modal on success
   */
  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    // Prevent page from refreshing
    e?.preventDefault();
    await handleUpdate(template.id!);
  };

  const isLastBlock = (index: number) => index === templateBlocks.length - 1;

  return (
    <div className="py-8 xl:px-8 flex flex-grow flex-col lg:flex-row bg-white">
      <div className="w-full lg:w-1/3 ">
        {/* Element Menu */}
        {!isReadOnly && <AddQuestionMenu addTemplateElement={addTemplateElement} />}
      </div>

      <div className="lg:w-2/3 justify-between lg:order-first">
        {/* Template Title */}
        <form onSubmit={handleSubmit}>
          {/* Template Title */}
          <input
            className="w-3/4 pb-2 text-xl mt-8 lg:mt-0 mb-12 text-gray-600 font-medium outline-none border-b border-gray-300 focus:text-gray-800 focus:border-indigo-600 focus:border-b-2 transition-border duration-300"
            type="text"
            placeholder="Type Meeting Template Title Here"
            value={templateTitle}
            onChange={(e) => setTemplateTitle(e.target.value)}
            disabled={isReadOnly}
            onBlur={(e) => setTemplateTitle(e.target.value.trim())}
            required
          />

          {/* Template Block Section */}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={templateBlocks} strategy={verticalListSortingStrategy}>
              <ul id="template-builder" className=" space-y-10 ">
                {/* Empty State */}
                {templateBlocks.length === 0 ? (
                  <div className="relative block w-full rounded-lg p-16 text-center border border-dashed border-gray-300">
                    <DocumentPlusIcon className="mx-auto h-7 w-7 stroke-1 text-gray-400" />
                    <span className="mt-2 block text-sm text-gray-500 text-normal">
                      Start building your template using the "Add a Question" menu!
                    </span>
                  </div>
                ) : (
                  templateBlocks.map((block, index) => {
                    return (
                      <li key={index}>
                        <TemplateBlock
                          index={index}
                          key={block.id}
                          id={block.id}
                          question={block.question}
                          setQuestion={(newQuestion) => setBlockQuestionById(block.id, newQuestion)}
                          templateElementType={block.type}
                          deleteBlock={() => removeTemplateElementById(block.id)}
                          isReadOnly={isReadOnly || newTemplateBlockIds.has(block.id)} // ##PreventNewBlockUpdate
                          isHighlighted={highlightIndex === block.position}
                        >
                          {/* Option List for Elements that need it */}
                          {doesBlockNeedOptions(block) ? (
                            <OptionList
                              options={block.optArgs!.options!}
                              setOptions={(options: string[]) =>
                                setBlockOptionsById(block.id, { options })
                              }
                              isReadOnly={isReadOnly || newTemplateBlockIds.has(block.id)}
                            />
                          ) : null}

                          {/* Length of list input for elements that need it */}
                          {doesBlockNeedLength(block) ? (
                            <LengthofList
                              lengthOfList={block.optArgs?.lengthOfList}
                              onChange={(lengthOfList: number) =>
                                setBlockOptionsById(block.id, { lengthOfList })
                              }
                              isReadOnly={isReadOnly || newTemplateBlockIds.has(block.id)}
                            />
                          ) : null}
                        </TemplateBlock>
                      </li>
                    );
                  })
                )}
              </ul>
            </SortableContext>
          </DndContext>
        </form>
        {/* Error when attempting to update existing template */}
        {updateTemplateMutation.error && (
          <ErrorBanner message={updateTemplateMutation.error.message} />
        )}
        {/* Block mutation errors */}
        {createBlockMutation.error && <ErrorBanner message={createBlockMutation.error.message} />}
        {updateBlockMutation.error && <ErrorBanner message={updateBlockMutation.error.message} />}
        {deleteBlockMutation.error && <ErrorBanner message={deleteBlockMutation.error.message} />}

        {!isReadOnly && <SaveStatusIndicator status={saveStatus} />}
      </div>
    </div>
  );
}
