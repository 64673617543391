import React from 'react';
import logo from '../images/logo_transparent_100x100.png';

const AssistantLogo: React.FC = () => {
  return (
    <div className="mr-5 rounded-full bg-white shadow-[0_0_4px_rgba(99,102,241,0.4)] shrink-0 p-2">
      <img src={logo} alt="Assistant Logo" className="h-8 w-auto" />
    </div>
  );
};

export default AssistantLogo;
