import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

interface MarkdownRendererProps {
  content: string;
}

const RouterLink = (props: any): JSX.Element => {
  return props.href.match(/^\//) ? (
    <Link to={props.href}>{props.children}</Link>
  ) : (
    <a href={props.href}>{props.children}</a>
  );
};

const MarkdownRenderer: FC<MarkdownRendererProps> = ({ content }) => {
  return (
    <ReactMarkdown
      components={{ link: RouterLink }}
      className="prose max-w-none " //prose-sm sm:prose-base md:prose-lg"
      remarkPlugins={[remarkGfm]}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;
