import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useState } from 'react';
import { getCurrentUser } from '../../clients/userClient';
import ErrorBanner from '../../components/ErrorBanner';
import FeatureGate from './FeatureGate';

interface FeatureGateContextType {
  isLoading: boolean;
  featureGate: FeatureGate | undefined;
}

const FeatureGateContext = createContext<FeatureGateContextType | undefined>(undefined);

const ACCOUNT_ERROR_MESSAGE =
  "We're having trouble loading your account information. Some features may be limited. Please try refreshing the page, log in again, or contact us at support@notewell.ai.";

export const FeatureGateProvider = ({ children }: { children: React.ReactNode }) => {
  const [featureGate, setFeatureGate] = useState<FeatureGate | undefined>();

  const {
    data: user,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['getUser'],
    queryFn: getCurrentUser,
    retry: 3 // TODO: Remove app-wide retry suppression, then remove this line
  });

  useEffect(() => {
    if (user) setFeatureGate(new FeatureGate(user.tier));
  }, [user]);

  return (
    <FeatureGateContext.Provider value={{ featureGate, isLoading }}>
      {isError && (
        <div className="fixed z-50 top-0 text-align align-items align-center px-10 pt-6 lg:ml-80 mx-auto">
          <ErrorBanner message={ACCOUNT_ERROR_MESSAGE} className="inline-flex w-fit" />
        </div>
      )}
      {children}
    </FeatureGateContext.Provider>
  );
};

export const useFeatureGate = () => {
  const context = useContext(FeatureGateContext);
  if (context === undefined) {
    throw new Error('useFeatureGate must be used within a FeatureGateProvider');
  }
  return context;
};
